import React from "react";
import styled from "styled-components";

import Constants from "../constants";
import InternalLink from "./internal-link";

const TimelineConstants = {
  blog: "blog",
  done: "done",
  life: "life",
  today: "today",
  travel: "travel",
  us: "us!",
  you: "you!",
};

const isCurrentLocation = ({ tagLabel }) => {
  return (
    tagLabel &&
    (tagLabel === TimelineConstants.us || tagLabel === TimelineConstants.you)
  );
};

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  position: relative;

  &::after {
    background-color: ${Constants.Colors.headerTheme};
    content: "";
    height: 100%;
    left: calc(50% - 2px);
    position: absolute;
    width: 4px;
  }
`;

const TimelineItemWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  padding-right: 30px;
  position: relative;
  width: 50%;

  &:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 0;
  }
`;

const TimelineItemContent = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  max-width: 70%;
  padding: 15px 15px ${({ hasLink }) => (hasLink ? "5" : "0")}px 10px;
  position: relative;
  text-align: center;
  width: 400px;

  &::after {
    background-color: #fff;
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
    content: " ";
    height: 15px;
    position: absolute;
    right: -7.5px;
    top: calc(50% - 7.5px);
    transform: rotate(45deg);
    width: 15px;
  }

  @media (max-width: 1023px) {
    max-width: 100%;
  }

  @media (max-width: ${Constants.mobileWidth}) {
    align-items: center;
  }

  // prettier-ignore
  ${TimelineItemWrapper}:nth-child(odd) & {

  &::after {
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
    left: -7.5px;
    right: auto;
  }

  @media (max-width: ${Constants.mobileWidth}) {
    align-items: center;
    text-align: center;
  }
}
`;

const TimelineTag = styled.span`
  background: ${({ color }) => color};
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 2px 5px;
  position: absolute;
  text-transform: uppercase;

  // prettier-ignore
  ${TimelineItemWrapper}:nth-child(odd) & {
  left: auto;
  right: 5px;
}

  @media (max-width: ${Constants.mobileWidth}) {
    padding: 0px 5px;
    width: calc(100% - 10px);
  }
`;

const TimelineText = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0;
  max-width: 250px;
`;

const TimelineCircle = styled.span`
  background-color: ${({ tagLabel }) =>
    isCurrentLocation({ tagLabel }) ? `#ef665c` : `#fff`};
  border: 3px solid ${Constants.Colors.headerGray};
  border-radius: 50%;
  height: 20px;
  position: absolute;
  right: -40px;
  top: calc(50% - 10px);
  width: 20px;
  z-index: 100;

  ${TimelineItemWrapper}:nth-child(odd) & {
    left: -40px;
    right: auto;
  }
`;

const TimelineTime = styled.time`
  align-self: flex-end;
  color: #777;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;

  // prettier-ignore
  ${TimelineItemWrapper}:nth-child(odd) & {
  align-self: flex-start;
}

  @media (max-width: ${Constants.mobileWidth}) {
    align-self: center;
    margin-top: 20px;

    // prettier-ignore
    ${TimelineItemWrapper}:nth-child(odd) & {
    align-self: center;
  }
  }
`;

const TimelineLink = styled.a`
  box-shadow: none;
  text-decoration: none;
  &::after {
    content: " →";
    font-size: 12px;
  }

  @media (max-width: ${Constants.mobileWidth}) {
    &::after {
      display: none;
    }
  }
`;

const getAppropriateLink = (timelineData, tagLabel) => {
  if (tagLabel && tagLabel === TimelineConstants.blog) {
    return (
      <InternalLink
        to={timelineData.linkUrl}
        color={Constants.Colors.headerTheme}
      >
        {timelineData.linkText}
      </InternalLink>
    );
  } else if (timelineData.linkUrl && timelineData.linkUrl.length > 0) {
    return (
      <TimelineLink href={timelineData.linkUrl} target="_blank" rel="noopener">
        {timelineData.linkText}
      </TimelineLink>
    );
  } else {
    return null;
  }
};

const getTimelineTagColor = (tagType) => {
  const tagColors = {
    [TimelineConstants.blog]: `#69a176`,
    [TimelineConstants.life]: `#317067`,
    [TimelineConstants.us]: `#ef665c`,
    [TimelineConstants.travel]: `#83c5bc`,
    [TimelineConstants.you]: `#ef665c`,
  };

  return tagColors[tagType] || Constants.Colors.headerGray;
};

const TimelineItem = ({ timelineData }) => {
  return timelineData.text && timelineData.text.length > 0 ? (
    <TimelineItemWrapper
      id={timelineData.tagLabel === TimelineConstants.us ? "#current" : null}
    >
      <TimelineItemContent hasLink={timelineData.linkUrl}>
        <TimelineTag color={getTimelineTagColor(timelineData.tagLabel)}>
          {timelineData.tagLabel}
        </TimelineTag>
        <TimelineTime>
          {timelineData.dateString || timelineData.date.toDateString()}
        </TimelineTime>
        <TimelineText>{timelineData.text}</TimelineText>
        {getAppropriateLink(timelineData, timelineData.tagLabel)}
        <TimelineCircle tagLabel={timelineData.tagLabel} />
      </TimelineItemContent>
    </TimelineItemWrapper>
  ) : null;
};

const TimelineWrapper = ({ timelineData }) => (
  <TimelineContainer>
    {timelineData.map((timeline, index) => (
      <TimelineItem timelineData={timeline} key={index} />
    ))}
  </TimelineContainer>
);

export default TimelineWrapper;

export { TimelineConstants };

import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import { genericScrollHandler } from "../hooks/useScrollHandler";
import Layout from "../components/layout";
import isDraftPost from "../logic/isDraftPost";
import replaceFootnotes from "../logic/replaceFootnotes";
import SEO from "../components/seo";
import TimelineWrapper, {
  TimelineConstants
} from "../components/timeline-wrapper";

const IntroContainer = styled.div`
  margin-top: 10px;
`;

const IntroText = styled.p`
  display: inline;
`;

const WhereWeAreLink = styled.a`
  display: inline;
`;

const defaultData = [
  {
    text: "You arrive on our blog - pretty neat!",
    date: new Date(),
    dateString: TimelineConstants.today,
    tagLabel: "you!"
  }
];

const arrangeAirtableTimelineData = (airtableNodes, timelineData) => {
  if (airtableNodes) {
    for (let index = 0; index < airtableNodes.length; index++) {
      const { node } = airtableNodes[index];
      if (node.data && node.data.text) {
        timelineData.push({
          text: node.data.text,
          date: new Date(node.data.date),
          tagLabel: node.data.tagLabel,
          linkText: node.data.linkText,
          linkUrl: node.data.linkUrl
        });
      }
    }
  }
};

const arrangePostData = (postData, timelineData) => {
  if (postData) {
    for (let index = 0; index < postData.length; index++) {
      const { node } = postData[index];
      if (node.title && node.created_date && !isDraftPost(node.title)) {
        timelineData.push({
          text: `"${replaceFootnotes(node.title)}"`,
          date: new Date(node.created_date),
          tagLabel: TimelineConstants.blog,
          linkText: "Read full post",
          linkUrl: node.fields.slug
        });
      }
    }
  }
};

const arrangeWhereWeAreNow = ({ node }, timelineData) => {
  timelineData.push({
    text: node.data.text,
    date: new Date(),
    dateString: TimelineConstants.today,
    tagLabel: TimelineConstants.us,
    linkText: node.data.linkText,
    linkUrl: node.data.linkUrl
  });
};

const Timeline = ({
  data: {
    allAirtable,
    allDropboxPaperDocumentNew,
    avatar,
    backdrop,
    logo,
    site,
    whereWeAre
  },
  location
}) => {
  const airtableNodes = allAirtable.edges;
  const postData = allDropboxPaperDocumentNew.edges;
  const whereWeAreNow = whereWeAre.edges[0];

  const timelineData = [];
  arrangeAirtableTimelineData(airtableNodes, timelineData);
  arrangePostData(postData, timelineData);
  arrangeWhereWeAreNow(whereWeAreNow, timelineData);

  Array.prototype.push.apply(timelineData, defaultData);

  timelineData.sort(
    (timelineOne, timelineTwo) => timelineOne.date - timelineTwo.date
  );

  return (
    <Layout avatar={avatar} backdrop={backdrop} location={location} site={site}>
      <SEO
        avatar={avatar}
        logo={logo}
        description="Where we're going in life, where we are today, and our active travel blog posts"
        site={site}
        title={"Timeline of our active travels and adventure blog - She & Jim"}
      />
      <IntroContainer>
        <IntroText>
          Scroll to discover where we've been, what we've blogged about, and
          where we are going. Don't worry, you can always easily{" "}
        </IntroText>
        <WhereWeAreLink href="#current" onClick={genericScrollHandler}>
          see where we are today!
        </WhereWeAreLink>
      </IntroContainer>
      {timelineData && <TimelineWrapper timelineData={timelineData} />}
    </Layout>
  );
};

export default Timeline;

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      ...Avatar
    }

    backdrop: file(absolutePath: { regex: "/mountains.png/" }) {
      ...Backdrop
    }

    logo: file(absolutePath: { regex: "/she-and-jim-logo.jpg/" }) {
      ...Logo
    }

    allAirtable(
      filter: { data: { tagLabel: { ne: "us" } }, table: { eq: "Timeline" } }
    ) {
      ...Timeline
    }

    whereWeAre: allAirtable(
      filter: { data: { tagLabel: { eq: "us" } }, table: { eq: "Timeline" } }
    ) {
      ...Timeline
    }

    allDropboxPaperDocumentNew(
      filter: { fields: { status: { eq: "active" } } }
    ) {
      ...DropboxPaperNodeEssentials
    }

    site {
      siteMetadata {
        authors
        social {
          instagram
        }
        siteUrl
        subTitle
        title
      }
    }
  }
`;
